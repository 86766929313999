.tail::before {
  left: 39px;
  right: auto;
  width: 10px;
  position: absolute;
  top: -7px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}
.tail::after {
  left: 40px;
  width: 10px;
  position: absolute;
  top: -6px;
  right: auto;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: '';
}
.DayPicker {
  font-size: 0.9rem !important;
}
.DayPicker-Day {
  padding: 0.2em 0.5em !important;
}
.DayPicker-Day--disabled {
  pointer-events: none !important;
}
.DayPicker-Day--today {
  font-weight: bold !important;
}
.DayPicker-Day--highlighted {
  color: rgba(6, 95, 70, 0.8) !important;
  background-color: rgba(209, 250, 229, 0.9) !important;
  font-weight: bold;
}
.DayPicker-Day--initial {
  color: rgb(220, 38, 38, 0.6) !important;
  font-weight: bold !important;
}
